const common = {
    white: (opacity) =>
      opacity ? `rgba(255, 255, 255, ${opacity / 100})` : "white",
    black: (opacity) =>
      opacity ? `rgba(0, 0, 0, ${opacity / 100})` : "black",
    gray: (opacity) =>
      opacity ? `rgba(136, 136, 136, ${opacity / 100})` : "#888888",
    purple: (opacity) => 
      opacity ? `rgba(110, 16, 204, ${opacity / 100})` : "#6E10CC",
    red: (opacity) =>
      opacity ? `rgba(252, 79, 79, ${opacity / 100})` : "#FC4F4F",
    green: (opacity) =>
      opacity ? `rgba(74, 216, 113, ${opacity / 100})` : "#4AD871",
    blue: (opacity) =>
      opacity ? `rgba(60, 137, 255, ${opacity / 100})` : "#3C89FF",
    yellow: (opacity) =>
      opacity ? `rgba(255, 191, 68, ${opacity / 100})` : "#FFBF44",
  };
  
  const light = {
    text: common.black,
    background: common.white,
  };
  
  const dark = {
    text: common.white,
    background: common.black,
  };
  
  const colors = { light, dark, common };
  
  export default colors;