import styled from '@emotion/styled'
import React from 'react'

import usePointer from '../tools/usePointer'

const StyledContents = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 240px);
  column-gap: 30px;
  margin: 40px 0;
  justify-content: center;

  @media (max-width: 1080px) {
    grid-template-columns: repeat(2, 240px);
    row-gap: 30px;
  }

  @media (max-width: 520px) {
    grid-template-columns: 100%;
    align-self: stretch;
    column-gap: 0;
    row-gap: 30px;
  }

  & a {
    width: 100%;
    height: 100px;
  }
`

const StyledContentsButton = styled.button`
  width: 100%;
  height: 100%;
  cursor: pointer;

  font-size: 16px;

  background-color: ${({theme}) => theme.colors.gray(10)};
  border: 0;
  border-radius: 10px;

  transition: background-color 0.07s;

  ${({theme, isHover, isClicked}) => isHover
    ? isClicked
      ? `
        background-color: ${theme.colors.gray(24)};
      `
      : `
        background-color: ${theme.colors.gray(16)}; 
      `
    : ""
  }

  ${({theme, isTouched}) => isTouched && `
    background-color: ${theme.colors.gray(16)}; 
  `}
`

const ContentsButton = ({children}) => {
  const { pointerEvents, pointerValues } = usePointer();

  return <StyledContentsButton {...pointerValues} {...pointerEvents}> {children} </StyledContentsButton>
}

const Contents = () => {
  return (
    <StyledContents>
      <a href="/" rel="noreferrer">
        <ContentsButton>
          바로가기 1
        </ContentsButton>
      </a>
      <a href="/" rel="noreferrer">
        <ContentsButton>
        바로가기 2
        </ContentsButton>
      </a>
      <a href="/" rel="noreferrer">
        <ContentsButton>
        바로가기 3
        </ContentsButton>
      </a>
      <a href="/" rel="noreferrer">
        <ContentsButton>
        바로가기 4
        </ContentsButton>
      </a>
    </StyledContents>
  )
}

export default Contents