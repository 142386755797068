import styled from '@emotion/styled';
import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from "react-dom";
import { BrowserRouter, Link } from 'react-router-dom';

import { Logo } from './'
import usePointer from '../tools/usePointer';
import { useTheme } from '@emotion/react';

const StyledHeader = styled.header(({theme}) => `
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
  background-color: ${theme.colors.background()};
  transition: background-color 0.3s;

  & .header-container {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    max-height: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: min-content auto;
    padding: 0 30px;
    box-sizing: border-box;

    & .header-left, & .header-right {
      display: flex;
      align-items: center;
    }

    & .header-right {
      justify-content: flex-end;
      column-gap: 20px;
    }
  }
`)

const StyledHeaderButton = styled.button`
  cursor: pointer;
  position: relative;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-family: Icons;
  font-size: 24px;

  &:after {
    content: "";
    border-radius: 50%;
    position: absolute;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    left: -3px;
    top: -3px;
    background-color: transparent;
    transition: background-color 0.1s;
    pointer-events: none;
    z-index: -1;
  }

  ${({theme, isHover, isClicked}) => isHover
    ? isClicked
      ? `
        &:after {
          background-color: ${theme.colors.gray(35)};
        }
      `
      : `
        &:after {
          background-color: ${theme.colors.gray(15)};
        }
      `
    : ""
  }

  ${({theme, isTouched}) => isTouched && `
    &:after {
      background-color: ${theme.colors.gray(15)};
    }
  `}
`

const StyledSettingsPopup = styled.div(({theme}) => `
  width: 600px;
  height: fit-content;
  border-radius: 10px;

  background-color: ${theme.colors.background()};

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`)

const StyledPlusPopup = styled.div(({theme}) => `
  width: 600px;
  height: fit-content;
  border-radius: 10px;

  background-color: ${theme.colors.background()};

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`)

const StyledPopupBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
`

const StyledCloseButton = styled.div`
  float: right;
  width: 40px;
  height: 40px;
  margin: 20px;
  cursor: pointer;
  font-size: 30px;
`

const StyledPopupButton = styled.button`
  padding: 10px 20px;
  cursor: pointer;
  
  font-size: 14px;

  background-color: ${({theme}) => theme.colors.gray(10)};
  border: 0;
  border-radius: 10px;

  transition: background-color 0.07s;

  ${({theme, isHover, isClicked}) => isHover
    ? isClicked
      ? `
        background-color: ${theme.colors.gray(24)};
      `
      : `
        background-color: ${theme.colors.gray(16)}; 
      `
    : ""
  }

  ${({theme, isTouched}) => isTouched && `
    background-color: ${theme.colors.gray(16)}; 
  `}
`

const Contents = styled.div`
  margin: 50px 30px;
  h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 60px;
  }
  img {
    margin-top: 60px;
    width: 300px;
  }
`

const HeaderButton = ({children, ...props}) => {
  const { pointerEvents, pointerValues } = usePointer();
  
  return <StyledHeaderButton {...pointerEvents} {...pointerValues} {...props}> {children} </StyledHeaderButton>
}

const PopupButton = ({children, ...props}) => {
  const { pointerEvents, pointerValues } = usePointer();
  
  return <StyledPopupButton {...pointerEvents} {...pointerValues} {...props}> {children} </StyledPopupButton>
}

const ThemeButton = ({children, ...props}) => {
  const { pointerEvents, pointerValues, utilities } = usePointer();
  const { upTime, downTime, setIsClicked, setIsTouched, setIsHover } = utilities;

  const { change, mode } = useTheme();

  const TimerRef = useRef(null);

  useEffect(() => {
    TimerRef.current = setTimeout(() => {
      change("auto");
      setIsClicked(false);
      setIsTouched(false);
      setIsHover(false);
    }, 2000)
  }, [downTime]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (downTime - upTime < 2000 && upTime !== 0) {
      change(mode === "light" ? "dark" : "light")
    }
    clearTimeout(TimerRef.current) 
  }, [upTime]) // eslint-disable-line react-hooks/exhaustive-deps

  return <StyledHeaderButton {...pointerEvents} {...pointerValues} {...props}> {children} </StyledHeaderButton>
}

const useBackgroundClick = (ref, callback) => {
  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback?.();
      }
    };

    window.addEventListener("mousedown", handleClick);

    return () => window.removeEventListener("mousedown", handleClick);
  }, [ref, callback]);
}

const PopupContainer = ({ children }) => {
  return createPortal(<>{children}</>, document.getElementById("popup"));
};

const SettingsPopup = ({ onClose }) => {
  const popupRef = useRef(null);
  const handleClose = () => {
    onClose?.();
  };

  useEffect(() => {
    const $body = document.querySelector("body");
    const overflow = $body.style.overflow;
    $body.style.overflow = "hidden";
    return () => {
    	$body.style.overflow = overflow
    };
  }, []);

  useBackgroundClick(popupRef, handleClose);

  return (
    <PopupContainer>
      <StyledPopupBackground>
        <StyledSettingsPopup ref={popupRef}>
          <StyledCloseButton onClick={handleClose}>
            X
          </StyledCloseButton>
          <Contents>
            <h1>This is a SettingsPopup</h1>
            <PopupButton>example button</PopupButton>
          </Contents>
        </StyledSettingsPopup>
      </StyledPopupBackground>
    </PopupContainer>
  );
}

const PlusPopup = ({ onClose }) => {
  const popupRef = useRef(null);
  const handleClose = () => {
    onClose?.();
  };

  useEffect(() => {
    const $body = document.querySelector("body");
    const overflow = $body.style.overflow;
    $body.style.overflow = "hidden";
    return () => {
    	$body.style.overflow = overflow
    };
  }, []);

  useBackgroundClick(popupRef, handleClose);

  return (
    <PopupContainer>
      <StyledPopupBackground>
        <StyledPlusPopup ref={popupRef}>
          <StyledCloseButton onClick={handleClose}>
            X
          </StyledCloseButton>
          <Contents>
            <h1>This is a PlusPopup</h1>
            <PopupButton>example button</PopupButton>
          </Contents>
        </StyledPlusPopup>
      </StyledPopupBackground>
    </PopupContainer>
  );
}

const Header = () => {
  const { mode } = useTheme();
  const [SettingsPopupIsOpen, setSettingsPopupIsOpen] = useState(false);
  const [PlusPopupIsOpen, setPlusPopupIsOpen] = useState(false);

  const onClickSettingsButton = () => {
    setSettingsPopupIsOpen(true);
  };

  const onClickPlusButton = () => {
    setPlusPopupIsOpen(true);
  };

  return (
      <StyledHeader>
        <div className='header-container'>
          <div className="header-left">
            <BrowserRouter>
              <Link to = "/">
                  <Logo />
              </Link>
            </BrowserRouter>
          </div>
          <div className="header-right">
            <HeaderButton onClick={onClickSettingsButton}>
              settings
            </HeaderButton>
            <HeaderButton onClick={onClickPlusButton}>
              plus
            </HeaderButton>
            <ThemeButton>{mode === "light" ? "sun" : "moon"}</ThemeButton>
            {SettingsPopupIsOpen && (<SettingsPopup
              open={SettingsPopupIsOpen}
              onClose={() => {
                setSettingsPopupIsOpen(false);
              }}
            />)}
            {PlusPopupIsOpen && (<PlusPopup
              open={PlusPopupIsOpen}
              onClose={() => {
                setPlusPopupIsOpen(false);
              }}
            />)}
          </div>
        </div>
      </StyledHeader>
  )
}

export default Header