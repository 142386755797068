import styled from "@emotion/styled";
import React from "react";

import { Header, Search, Contents } from './components';

const StyledScrollFrame = styled.div`
  padding: 0 20px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 80px);
  margin-top: 80px;
`

const StyledFrame = styled.div`
  box-sizing: border-box;
  height: 100%;
`

const App = () => {
  return (
    <StyledScrollFrame>
      <StyledFrame>
        <Header />
        <Search />
        <Contents />
      </StyledFrame>
    </StyledScrollFrame>
  );
}

export default App;
