import { css } from "@emotion/react";

const global = (theme) => css`
  :root {
    font-family: var(--font);
    font-size: 14px;
    color: ${theme.colors.text()};
    color-scheme: ${theme.mode};
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    touch-action: manipulation;
    word-break: keep-all;
    box-sizing: border-box;
  }

  input,
  button,
  select {
    font-family: var(--font);
    box-sizing: border-box;
    &:focus-visible {
      outline: 1px dashed ${theme.colors.text()};
      outline-offset: 4px;
    }
  }
  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  #root,
  #_next {
    overflow: hidden;
    height: 100%;
  }
  html {
    background-color: ${theme.colors.background(100)};
    ${theme.isChanging &&
    `
transition: background-color 0.3s, color 0.3s;
will-change: background-color, color;
`}
  }
`;

export default global;