import styled from '@emotion/styled';
import React, { useState } from 'react';

import usePointer from '../tools/usePointer';

const searchEngines = [
  { name: 'Google', url: 'https://www.google.com/search?q=' },
  { name: 'Naver', url: 'https://search.naver.com/search.naver?query=' },
  { name: 'Daum', url: 'https://search.daum.net/search?q=' },
  { name: 'Nate', url: 'https://search.daum.net/nate?w=tot&DA=NAD&q=' },
  { name: 'You', url: 'https://you.com/search?q=' },
];

const StyledSearchContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    align-self: stretch;
    position: relative;

  min-height: 56px;
  height: min(calc(50% - 80px), calc(100% - 180px));

  @media (max-width: 1080px) {
    height: min(calc(50% - 80px), calc(100% - 310px));
  }

  @media (max-width: 520px) {
    height: min(50% - 80px);
  }
`

const StyledSearchBox = styled.div`
  max-width: 480px;
  width: 100%;
  height: 56px;
  overflow: hidden;
  border-radius: 28px;
  padding: 0 10px 0 20px;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.colors.gray(15)};
  border: 1px solid ${({theme}) => theme.colors.gray(10)};
  transition: background-color 0.07s;

  ${({theme, isHover}) => isHover
      ? `
        background-color: ${theme.colors.gray(20)};
      `
      : ""
  }

  ${({theme, isTouched}) => isTouched && `
    background-color: ${theme.colors.gray(20)}; 
  `}
  
  & form {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: min-content 1fr min-content;

    & input, & button, & select {
      outline: none;
      border: 0;
    }

    & input, & select {
      background-color: transparent;
      font-size: 14px;
    }

    & select option {
      background-color: ${({theme}) => theme.colors.background()};
      height: 20px;
    }
  }
`

const StyledSearchButton = styled.button`
  font-family: Icons;
  cursor: pointer;
  font-size: 18px;
  background-color: ${({theme}) => theme.colors.purple()};
  padding: 0 18px;
  height: 36px;
  border-radius: 20px;
  align-self: center;
  color: ${({theme}) => theme.colors.white()};
  transition: background-color 0.07s;

  ${({theme, isHover, isClicked}) => isHover
    ? isClicked
      ? `
        background-color: ${theme.colors.purple(75)};
      `
      : `
        background-color: ${theme.colors.purple(85)}; 
      `
    : ""
  }

  ${({theme, isTouched}) => isTouched && `
    background-color: ${theme.colors.purple(85)}; 
  `}
`

const SearchButton = ({children, ...props}) => {
  const { pointerEvents, pointerValues } = usePointer();

  return <StyledSearchButton {...pointerEvents} {...pointerValues} {...props}>{children}</StyledSearchButton>
}

function Search() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEngine, setSelectedEngine] = useState(searchEngines[0]);

  const { pointerEvents, pointerValues } = usePointer();

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  }

  const handleEngineChange = (event) => {
    const engine = searchEngines.find((item) => item.name === event.target.value);
    setSelectedEngine(engine);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchTerm) window.location.href = selectedEngine.url + searchTerm;
    else alert("검색어를 입력해주세요.")
  }

  return (
    <StyledSearchContainer>
      <StyledSearchBox {...pointerValues}>
        <form onSubmit={handleSubmit}>
          <select {...pointerEvents} value={selectedEngine.name} onChange={handleEngineChange}>
            {searchEngines.map((engine) => (
              <option key={engine.name} value={engine.name}>{engine.name}</option>
            ))}
          </select>
          <input {...pointerEvents} type="text" placeholder="검색어 입력" value={searchTerm} onChange={handleChange} />
          <SearchButton type="submit">search</SearchButton>
        </form>
      </StyledSearchBox>
    </StyledSearchContainer>
  );
}

export default Search;