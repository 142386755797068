import { Global, ThemeProvider as EmotionProvider } from "@emotion/react";
import { useEffect, useLayoutEffect, useState } from "react";

import colors from "./colors";
import global from "./global";

const ThemeProvider = ({ children }) => {
  const THEME_MODE = "theme-mode";

  const [userMode, setUserMode] = useState("auto");
  const [mode, setMode] = useState("light");
  const [isChanging, setIsChanging] = useState(false);

  useLayoutEffect(() => {
    // get user mode from storage
    const savedTheme = localStorage.getItem(THEME_MODE);
    setUserMode(savedTheme ? (savedTheme) : "auto");
  }, []);

  // translate user mode to theme mode and apply
  useLayoutEffect(() => {
    if (window.matchMedia) {
      // check system theme
      if (userMode === "auto") {
        window.matchMedia("(prefers-color-scheme: dark)").matches
          ? setMode("dark")
          : setMode("light");
      } else {
        setMode(userMode);
      }
    } else {
      setMode("dark");
    }
  }, [userMode]);

  // return animation state
  useEffect(() => {
    if (isChanging) setTimeout(() => setIsChanging(false), 300);
  }, [isChanging]);

  // subscribe user when user mode is "auto"
  useEffect(() => {
    const MediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    const listener = (event) => {
      if (event.matches) setMode("dark");
      else setMode("light");
    };

    if (userMode === "auto")
      MediaQuery && MediaQuery.addEventListener("change", listener);

    return () => {
      MediaQuery && MediaQuery.removeEventListener("change", listener);
    };
  }, [userMode]);

  // change theme
  const change = (mode) => {
    setIsChanging(true);
    setUserMode(mode);
    localStorage.setItem("theme-mode", mode);
  };

  // theme object
  const theme = {
    mode,
    colors: { ...colors[mode], ...colors["common"] },
    change,
    isChanging
  };

  return (
    <>
      <EmotionProvider theme={theme}>{children}</EmotionProvider>
      <Global styles={global(theme)} />
    </>
  );
};

export default ThemeProvider;